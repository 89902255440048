import React, {useState, useContext} from "react"
import {useForm} from "react-hook-form"
import _ from "lodash"
import {AppSync} from "../../libraries/appsync";
import Cookies from "js-cookie"
import qs from "qs"
import Button from "../Button"
import FcContext from "../Context/FcContext"
import classNames from "classnames";
import modStyle from "./index.module.css";
import eyeOpenImg from "../../images/Login/eye-open.svg";
import eyeCloseImg from "../../images/Login/eye-close.svg";

export default ({location}) => {
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState(null)
  const {register, errors, handleSubmit} = useForm();
  const {updateSession} = useContext(FcContext)
  const [pwInputType, setPwInputType] = useState('password')
  const [pwEyeImg, setPwEyeImg] = useState(eyeOpenImg)
  const queryString = qs.parse(_.get(location, "search"), {ignoreQueryPrefix: true})

  const formLogin = (data, e) => {
    e.preventDefault()

    setLoading(true)
    AppSync.post("/", {
      query: `
        query loginFcUser {
          loginFcUser(email: "${_.get(data, "email")}", password: "${_.get(data, "password")}", apiUser: "${_.get(queryString, "apiUser")}", apiSite: "${_.get(queryString, "apiSite")}", apiKey: "${_.get(queryString, "apiKey")}") {
            personal_c
            member_type
            sess
          }
        }
      `
    }).then(res => {

      if (_.has(res.data, "errors")) {
        setApiError("会員認証できません。ログインID（メールアドレス）、パスワード、会員期限日をご確認いただいても認証ができない場合はファンクラブへお問い合わせください。")
        setLoading(false)
        return
      }

      if (_.isEmpty(_.get(res.data, "data.loginFcUser.sess"))) {
        setApiError("会員認証できません。ログインID（メールアドレス）、パスワード、会員期限日をご確認いただいても認証ができない場合はファンクラブへお問い合わせください。")
        setLoading(false)
        return
      }

      typeof window !== "undefined" && window.localStorage.setItem(_.get(queryString, "apiUser"), JSON.stringify(_.get(res.data, "data.loginFcUser")))
      Cookies.set(process.env.STORE_SESSION_NAME+'fc', _.get(res.data, "data.loginFcUser.sess"), {expires: 1, domain: process.env.STORE_SESSION_DOMAIN})
      updateSession()

      if (_.get(queryString, "hin")) {
        window.location.href = `/product/detail/?id=${encodeURIComponent(_.get(queryString, "hin"))}`
      }
    })
  }

  const formErrorClass = validateResult => validateResult ? "error" : ""

  const changePasswordView = (_type, _img) => {
    setPwInputType(_type)
    setPwEyeImg(_img)
  }

  const showHidePassword = () => {
    pwInputType === "password"
      ? changePasswordView("text", eyeCloseImg)
      : changePasswordView("password", eyeOpenImg)
  }

  return (
    <div className={classNames({loading: loading}, "container", "bg-white", "inner", "form")}>
      <div>
        <h2>{_.get(queryString, "apiName")} 会員認証</h2>
        <p>
          会員限定商品をご注文頂くには、ファンクラブの会員認証が必要となります。<br />
          ログインID（メールアドレス）・パスワードを入力し［会員認証］ボタンを押してください。
        </p>
        {!_.isEmpty(apiError) && <p className="comment error">{apiError}</p>}

        <form onSubmit={handleSubmit(formLogin)}>
          <div className="field">
            <label htmlFor="email">ログインID（メールアドレス）</label>
            <input type="email" name="email" id="email" ref={register({required: true, pattern: /[^\s]+@[^\s]+/})} className={formErrorClass(errors.email)} />
            {errors.email && <p className="comment error">正しいメールアドレスを入力してください</p>}
          </div>
          <div className="field">
            <label htmlFor="password">パスワード</label>
            <div className={modStyle.pwField}>
              <input type={pwInputType} name="password" id="password" ref={register({required: true})} className={classNames(formErrorClass(errors.password), modStyle.pwInputBox)} />
              <span className={modStyle.pwShowHide} onClick={showHidePassword}>
                <img src={pwEyeImg} alt="" className={modStyle.pwEyeImg} />
              </span>
            </div>
            {errors.password && <p className="comment error">パスワードを入力してください</p>}
          </div>
          <div className="submit">
            <Button type="submit">会員認証</Button>
          </div>
        </form>
      </div>
      <div className={classNames("container", "bg-gray", "inner", "form", "contents-space")}>
        <div>
          <h3>ファンクラブ会員登録をされていない方へ</h3>
          <p className="explain">本商品は、ファンクラブサービスへの加入が必要です。ファンクラブサービスへの加入はこちらからお願い致します。</p>
          <form>
            <div className="submit">
              <a type="button" className="button-module--button--1AP7e" href={_.get(queryString, "register")} target="_blank">ファンクラブサイトへ</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
