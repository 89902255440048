import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import LoginFc from "../components/LoginFc"
import PageTitle from "../components/PageTitle";

export default ({location}) => (
  <Layout>
    <SEO title="会員認証"/>
    <section className="inner">
      <PageTitle>会員認証</PageTitle>
      <LoginFc location={location}/>
    </section>
  </Layout>
)